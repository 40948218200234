import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "slug": "stats",
  "title": "Sicurezza",
  "pretitle": "",
  "image": "../images/box.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ci prendiamo cura della vostra merce per tutto il viaggio.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      